import { BlockSettings } from '~/types/miscellaneous';

const useStoryblokBlock = (
    block: ComputedRef<any>,
    nextBlock?: ComputedRef<any>,
) => {
    const settings = computed<BlockSettings>(() => {
        return {
            backgroundColor: block.value.background_color || null,
            bottomAngle: block.value.bottom_angle || 'straight',
            arrowCallout:
                block.value.arrow_callout_type && block.value.arrow_callout_text
                    ? {
                          type: block.value.arrow_callout_type,
                          text: richTextToHtml(
                              block.value.arrow_callout_text,
                          ) as string,
                      }
                    : null,
            nextBlockBgColor: nextBlock?.value?.background_color
                ? nextBlock.value.background_color
                : null,
        };
    });

    const hasBottomAngleDownLeft = computed(
        () => block.value.bottom_angle === 'left',
    );

    const hasBottomAngleDownRight = computed(
        () => block.value.bottom_angle === 'right',
    );

    const hasBottomAngle = computed(
        () => hasBottomAngleDownLeft.value || hasBottomAngleDownRight.value,
    );

    const hasArrowCallout = computed(() => {
        return (
            block.value.arrow_callout_type !== '' &&
            block.value.arrow_callout_text !== ''
        );
    });

    return {
        settings,
        hasBottomAngle,
        hasBottomAngleDownLeft,
        hasBottomAngleDownRight,
        hasArrowCallout,
    };
};

export default useStoryblokBlock;
